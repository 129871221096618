import axios from "axios";
import msg from "../../util/msg.js";

export function list(param) {
  return axios.get("/ro/list", { params: param });
}

export function queryConfirm(id){
    axios.post("/ro/queryConfirm/" + id).then(data=>{
        msg.success();
    });
}

export function castConfirm(id){
    axios.post("/ro/castConfirm/" + id).then(data=>{
        msg.success();
    });
}