<template>
    <div>
        <el-form :inline="true" :model="form">
            <el-form-item>
                <el-input v-model="form.id" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.userId" placeholder="用户ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.email" placeholder="用户邮箱"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="form.paymentMethod" placeholder="支付方式" clearable>
                    <el-option v-for="item in Object.keys(PAYMENT_METHOD)" :key="item" :label="PAYMENT_METHOD[item]"
                        :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="form.status" placeholder="支付状态" clearable>
                    <el-option v-for="item in Object.keys(RECHARGE_ORDER_STATUS)" :key="item"
                        :label="RECHARGE_ORDER_STATUS[item]" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="queryTimeRange" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="page.list" border>
            <el-table-column label="ID">
                <template #default="{ row }">
                    {{ row.id }}
                    <el-tag :type="choseTag(row.status)">{{ RECHARGE_ORDER_STATUS[row.status] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发起时间">
            </el-table-column>
            <el-table-column label="用户">
                <template #default="{ row }">
                    [{{ row.userId }}]{{ row.email }}
                </template>
            </el-table-column>
            <el-table-column label="金额" prop="amount">

            </el-table-column>
            <el-table-column label="支付方式">
                <template #default="{ row }">

                    <el-popover placement="bottom" trigger="hover" width="800">
                        <el-descriptions :column="1" border>
                            <el-descriptions-item label="支付订单号" :labelStyle="{ width: '100px' }">
                                {{ row.pmOrderNo }}
                            </el-descriptions-item>
                            <el-descriptions-item label="支付信息" :labelStyle="{ width: '100px' }">
                                {{ row.payInfo }}
                            </el-descriptions-item>
                        </el-descriptions>
                        <span slot="reference">{{ PAYMENT_METHOD[row.paymentMethod] }}</span>
                    </el-popover>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template #default="{ row }">
                    <template v-if="row.status == 'PROCESSING'">
                        <el-button @click="queryConfirm(row.id)" type="primary" size="medium">查询确认</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-popconfirm title="是否强制通过订单？" @confirm="castConfirm(row.id)">
                            <el-button slot="reference" type="danger" size="medium">强制确认</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import { list, queryConfirm, castConfirm } from './ro'
import { PAYMENT_METHOD, RECHARGE_ORDER_STATUS } from '../../util/constants'
export default {
    name: "UserList",
    data() {
        return {
            PAYMENT_METHOD,
            RECHARGE_ORDER_STATUS,

            queryTimeRange: null,
            form: {
                pageNo: 1,
                pageSize: 20,
                id: null,
                userId: null,
                email: null,
                paymentMethod: null,
                status: null,
                ctStart: null,
                ctEnd: null,
            },

            page: {
                list: null,
                totalPage: null,
            },
        };
    },

    methods: {
        queryConfirm,
        castConfirm,
        loadList() {
            if (this.queryTimeRange != null) {
                this.form.ctStart = this.queryTimeRange[0];
                this.form.ctEnd = this.queryTimeRange[1];
            } else {
                this.form.ctStart = null;
                this.form.ctEnd = null;
            }
            list(this.form).then((data) => {
                this.page = data;
            });
        },
        choseTag(status) {
            if (status == 'SUCCESS') {
                return 'success'
            }
            if (status == 'FAIL') {
                return 'danger'
            }

            return '';
        },

    },

    created() {
        this.loadList();
    },

};
</script>